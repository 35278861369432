import React, { useState } from "react";
import Label from "../Label";
import { FileUploader } from "react-drag-drop-files";
import { icons } from "utils/constants";
import { useDispatch } from "react-redux";
import { throwError } from "store/slices";
import { formatFileSize } from "utils/helpers";
import "./UploadInput.scss";

const getBase64 = (file, callback) => {
  let reader = new FileReader();
  reader.onload = function () {
    const result = reader.result;
    callback(result);
  };
  reader.onerror = function (error) {};
  reader.readAsDataURL(file);
};

const UploadInput = ({
  label,
  isRequired,
  labelClass,
  multiple,
  handleChange,
  supportedFormats,
  maxFileSizeMB = 5,
  id,
}) => {
  const dispatch = useDispatch();
  const [selectedFiles, setSelectedFiles] = useState([]);

  const handleFileChange = (files) => {
    const myFiles = multiple ? Array.from(files) : [files];
    const newFiles = myFiles?.filter(
      (file) => !selectedFiles?.some((f) => f?.name === file?.name)
    );

    if (newFiles?.length < myFiles?.length) {
      dispatch(
        throwError({
          message: "Files are already selected.",
        })
      );
    }

    newFiles?.forEach((file) => {
      getBase64(file, (base64Result) => {
        if (multiple) {
          setSelectedFiles((prevFiles) => [...prevFiles, file]);
        } else {
          setSelectedFiles([file]);
        }

        if (typeof handleChange === "function") {
          handleChange({
            target: {
              id: id,
              value: base64Result,
              fileName: file?.name,
              file: file,
            },
          });
        } else {
          console.error("handleChange is not a function");
        }
      });
    });
  };

  const handleSizeError = (error) => {
    dispatch(
      throwError({
        message: `${error}. The maximum file size is ${maxFileSizeMB} MB.`,
      })
    );
  };

  const handleTypeError = (error) => {
    dispatch(
      throwError({
        message: `${error}. Please upload one of the following: ${supportedFormats?.join(
          ", "
        )}`,
      })
    );
  };

  const removeFile = (file) => {
    const updateFiles = selectedFiles?.filter((f) => f?.name !== file?.name);
    setSelectedFiles(updateFiles);
    if (typeof handleChange === "function") {
      handleChange({
        target: {
          id: id,
          value: "",
          fileName: "",
        },
      });
    }
  };

  return (
    <div className="upload-input-container">
      {label && (
        <Label label={label} required={isRequired} className={labelClass} />
      )}
      {selectedFiles?.length > 0 ? (
        <div className="file-uploaded-box d-flex align-items-center justify-content-between w-100 bg-f6ff">
          <div className="d-flex flex-column justify-content-center  w-100">
            <span className="file-name text-14-500 color-6866 text-center">
              {selectedFiles[0]?.name}
            </span>
            <span className="file-size text-14-500 color-6866 text-center">
              {formatFileSize(selectedFiles[0]?.size)}
            </span>
          </div>
          <i
            style={{ fontSize: "24px", fontWeight: 500 }}
            className="bi bi-trash delete-icon color-danger pointer"
            onClick={() => {
              removeFile(selectedFiles[0]);
            }}
          />
        </div>
      ) : (
        <div className="upload-section cpt-50 cpb-50 cps-30 cpe-30 text-center">
          <FileUploader
            handleChange={handleFileChange}
            name="file"
            types={supportedFormats}
            multiple={multiple}
            maxSize={maxFileSizeMB}
            onTypeError={handleTypeError}
            onSizeError={handleSizeError}
          >
            <div className="drop-area-box">
              <div>
                <img src={icons.UploadPaperIcon} alt="img" />
              </div>
              <div className="text-12-400 color-6866 cmt-6">
                Drag & drop files or{" "}
                <span className="color-new-car pointer">Browse</span>
              </div>

              <div className="cmt-8 text-12-400 color-6866">
                Supported formats: {supportedFormats.join(", ")}
              </div>
            </div>
          </FileUploader>
        </div>
      )}
    </div>
  );
};

export default UploadInput;
